@tailwind base;
@tailwind components;
@tailwind utilities;
/*body { font-family: "Noto Sans Thai", sans-serif;}*/
/*body { font-family: "Noto Sans Thai Looped", sans-serif; }*/
body { font-family: "Sarabun", sans-serif; font-weight: 300 }
/*
body {
    min-width: 1920px;
    min-height: 100vh;
}
*/
/*body { font-family: "K2D", sans-serif; }*/
h1, h2, h3, h4, h5, h6, .text-noto { font-family: "Noto Sans Thai", sans-serif;}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.2);
    border-radius: 10px;
}

.sc-white::-webkit-scrollbar-track {
    background-color: #fff;
}
::-webkit-scrollbar-track {
    background-color: #f8fafc;
}
:root {
    --color-primary: #3730a3;
    --color-secondary: #facc15;
}
.main-side-bar { min-width: 290px; width: 290px; }
.type-msg-area {resize: none;}
.message__recipient .chat-text-box:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    background: #e2e8f0;
    position: absolute;
    left: -1px;
    transform: rotate(45deg);
    top: 50%;
    z-index: -1;
    translate: 0% -50%;
}
.message__sender .chat-text-box:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    background: var(--color-primary);
    position: absolute;
    right: -1px;
    transform: rotate(45deg);
    top: 50%;
    z-index: -1;
    translate: 0% -50%;
}
.chat-text-box {
    overflow-wrap: break-word;
    z-index: 8;
    position: relative;
}
.logo svg {
    max-width: 100%;
}
.main-side-bar.collapsed .menu-link-warp, .main-side-bar.collapsed .left-sidebar-title {
    display: none;
}
.main-side-bar.collapsed {
    width: 80px;
    min-width: 80px;
}

.main-side-bar.collapsed .logo svg {
    padding: 0.84em 0;
}

.main-side-bar.collapsed li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}


.active svg {
    fill: var(--color-primary);
}

.custom-card-bg:before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(134 132 170) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}
.custom-card-bg:after {
    content: "";
    position: absolute;
    width: 221px;
    height: 219px;
    background: linear-gradient(210.04deg, rgb(121 96 172) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
}
.custom-card-bg-2:before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(255 140 32) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}
.custom-card-bg-2:after {
    content: "";
    position: absolute;
    width: 221px;
    height: 219px;
    background: linear-gradient(210.04deg, rgb(253 112 112) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
}

tbody .MuiInputBase-root:before, tbody .MuiInputBase-root:after, tbody .MuiInputBase-root:hover {
    border-bottom: 0 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.message__chats .message__sender {
    margin-bottom: 0.5em;
}
.MuiMenu-list {
    max-height: 200px;
}
.chat__sticky__post {
    position: absolute;
    left: 50%;
    top: 7%;
    width: max-content;
    transform: translate(-50%, 0);
    z-index: 1000;
}

.ql-container.ql-snow {
    min-height: 300px;
}
.status-select .MuiInputBase-root:hover:before, .status-select .MuiInputBase-root:after {
    border-bottom: none !important;
}
.message__chats strong {
    background: yellow;
    color: #000;
}
.lazy-load-image-background {
    margin-bottom: 5px;
}

html.dark {
    --color-primary: #facc15;
    --color-secondary: #3730a3;
}
html.dark .main-side-bar {
    background: #111;
    color: #fff;
}

html.dark .main-side-bar .menu-link-warp {
    color: #fff;
}

html.dark .main-side-bar .menu-link-warp.active {
    background: #2d3748;
}

html.dark .main-side-bar .menu-link-warp.active svg {
    fill: #facc15;
}

html.dark .main-side-bar .menu-link-warp svg {
    fill: #fff;
}

html.dark .main-side-bar .menu-link-warp:hover {
    background: #2d3748;
}

html.dark .main-side-bar .menu-link-warp:hover svg {
    fill: #facc15;
}

html.dark .main-side-bar .menu-link-warp.active:hover {
    background: #2d3748;
}

html.dark .main-side-bar .menu-link-warp.active:hover svg {
    fill: #facc15;
}

html.dark .bg-main-bg-color, html.dark .bg-slate-50, html.dark .bg-gray-50 {
    background: #000!important;
    color: #ccc !important;
}

html.dark .bg-white {
    background: #000!important;
}
html.dark .bg-slate-100, html.dark .bg-slate-200, html.dark  .message__recipient .chat-text-box:after {
    background-color: #111!important;
    color:  #f9f9f9 !important;
}
html.dark .hover\:bg-slate-100:hover, html.dark .hover\:bg-slate-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 17 17);
}

html.dark span.MuiChip-label , html.dark .MuiChip-root, html.dark .MuiInput-input {
    color: #fff !important;
}

html.dark ::-webkit-scrollbar-track {
    background-color: #000000 !important;
}

html.dark ::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,.2);
    border-radius: 10px;
}

html.dark .border-slate-150 {
    border-color: #111 !important;
}

html.dark .bg-indigo-800 {
    background-color: #2e2e2e !important;
}

html.dark .message__sender .chat-text-box:after{
    background-color: #2e2e2e !important;
}

html.dark .border-gray-300, html.dark .border, html.dark .border-t, html.dark .border-b-slate-150{
    border-color: #2b2b2b !important;
}

html.dark svg.MuiSvgIcon-root {
    fill: #8e8e8e !important;
}

html.dark .MuiAvatar-root svg {
    fill: #fff !important;
}

html.dark .MuiPaper-root {
    background-color: #2e2e2e !important;
}
/*
้html.dark .text-slate-500 {
    color: #777 !important;
}
*/